import React, { useEffect, useRef, useState } from 'react'
import { Blurhash } from 'react-blurhash'

const ImageComponent = ({ src, style }) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        const img = new Image()
        img.onload = () => {
            setImageLoaded(true)
        }
        img.src = src
    }, [src])

    const widthPercentage = 100
    const aspectRatio = 5 / 3; // Example aspect ratio

    useEffect(() => {
        // Calculate and set container height based on width percentage
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const height = containerWidth / aspectRatio;
            setContainerHeight(height);
        }
    }, [widthPercentage]);

    return (
        <>
            {!imageLoaded && (
                <div
                    ref={containerRef}
                    style={{
                        position: 'relative',
                        width: `${widthPercentage}%`,
                        height: `${containerHeight}px`,// Set container width
                        // paddingBottom: `${100 / aspectRatio}%`, // Set container aspect ratio
                        overflow: 'hidden', // Ensure overflow is hidden
                    }}
                >
                    <Blurhash
                        hash='LGFYu-^*RPa~_4D%%NoL?cD%R.xu'
                        punch={1}
                        width={"100%"}
                        height={"100%"}
                        
                    />
                </div>
            )}
            {imageLoaded && (
                <img src={src} style={{aspectRatio: aspectRatio, objectFit: 'cover'}} alt="" />
            )}
        </>
    )
}

export default ImageComponent